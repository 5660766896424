body {
  background: black;
  font-family: 'Open Sans';
  background: url("./images/starfield/front.png");
  max-width: 100vw;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.lunar__container {
  width: 100%;
  color: #ffffff;
  position: relative;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
}

.lunar__overlay {
  opacity: 0;
  visibility: visible;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000;
  z-index: 1000;
  pointer-events: none;
}

.lunar__overlay--loading {
  opacity: 1;
  bottom: 0;
  visibility: none;
}

.lunar__overlay--loading .lunar__loader {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
}

#webgl-container {
  align-self: center;
  align-items: center;
  cursor: pointer;
  max-height: 100vh;
}

@media (max-width: 850px) {
  #webgl-container {
    display: none;
  }
}

.lunar__hero,
.lunar__header {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.lunar__hero__left {
  padding: 50px 30px 30px;
  max-width: 400px;
  max-height: 100vh;
  overflow-y: scroll;
}

.lunar__hero__left::-webkit-scrollbar {
  display: none;
}

.lunar__logo {
  width: 100px;
  height: 50px;
  margin-left: 8px;
  background: url("./images/logo3.svg") no-repeat;
  background-size: 100px auto;
  display: block;
  font-size: 0;
}

.lunar__headline {
  font-weight: 700;
  font-family: "Avenir Next", "Open Sans", sans-serif;
  max-width: 400px;
  font-size: 74px;
  line-height: 80px;
  color: #ffffff;
  margin: 110px 0 30px;
  z-index: 10;
}

.lunar__subheadline {
  font-weight: 200;
  font-family: "Open Sans", sans-serif;
  max-width: 360px;
  font-size: 24px;
  color: #ffffff;
  opacity: .8;
  margin: 30px 0;
}

.lunar__bg-video {
  display: none;
}

.lunar__hero__left,
.lunar__headline,
.lunar__subheadline {
  min-width: 370px;
}

@media (max-width: 375px) {
  .lunar__hero__left,
  .lunar__headline,
  .lunar__subheadline {
    min-width: 320px;
  }
}


@media (max-width: 500px) {
  .lunar__headline {
    font-size: 64px;
    margin-top: 50px;
  }
}

@media (max-width: 700px) {
  .lunar__bg-video {
    display: block;
    position: absolute;
    top: 100px;
    z-index: -1;
    width: 70%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .lunar__hero {
    background-image: linear-gradient(transparent 30%, black);
    justify-content: center;
  }
}

@media (max-width: 450px) {
  .lunar__bg-video {
    width: 130%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.lunar__progress-bar {
  color: #ffffff;
  font-size: 11px;
  display: flex;
  max-width: 355px;
  white-space: nowrap;
}

.lunar__progress-bar--outer {
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  width: 100%;
  flex-grow: 1;
  max-width: 300px;
  margin: 5px;
  margin-left: 15px;
}

.lunar__progress-bar--inner {
  background-color: #D8D8D8;
  border-radius: 3px;
  height: 4px;
  min-width: 10px;
  animation: scaleRight .5s ease-out forwards;
  animation-delay: 1s;
  transform: scaleX(0);
  transform-origin: left;
}

.lunar__web3 {
  font-size: 12px;
  text-align: center;
  margin-top: 0;
}

.lunar__web3 a {
  color: white;
}

.lunar__cta {
  font-size: 16px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  background: transparent;
  padding: 12px;
  width: 100%;
  max-width: 350px;
  transition: all .2s;
  cursor: pointer;
  margin: 20px 0 50px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.lunar__cta:disabled {
  opacity: .7;
}

.lunar__cta:not(:disabled):hover {
  transform: scale(1.02);
}

.lunar__cta:focus {
  outline: 0;
}

.lunar__cta:before {
  content: "";
  width: 100%;
  border: 1px solid rgba(255,255,255,.2);
  height: 40px;
  width: 100%;
  max-width: 400px;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  opacity: 0;
  transform: scale(1);
  transition: all .2s;
  border-radius: 3px;
}

.lunar__cta:hover:before {
  opacity: 1;
  transform: scale(.95);
}

.lunar__cta:active:before {
  transform: scale(1);
  opacity: 0;
}

.lunar__cta:not(:disabled):active {
  transform: scale(1);
  outline: 0;
}

.lunar__cta-row {
  display: flex;
}

.lunar__cta-row .lunar__cta {
  margin-top: 0;
}

.lunar__cta-row .lunar__cta {
  margin-right: 15px;
}

.lunar__cta-row .lunar__cta:last-child {
  margin-right: 0;
}

.lunar__detail,
.lunar__profile {
  margin-top: 32px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  min-width: 330px;
  max-width: 360px;
}

.lunar__detail__back {
  text-decoration: none;
  color: #ffffff;
  margin: 20px 0;
  font-size: 14px;
  opacity: 0.9;
  display: inline-block;
  transition: all .2s;
}

.lunar__detail__back:hover {
  opacity: 1;
  transform: translateX(8px);
}

.lunar__detail__back:before {
  content: "←";
  display: block;
  position: absolute;
  opacity: 0;
  transform: translateX(0px);
  transition: all .2s;
}

.lunar__detail__back:hover:before {
  transform: translateX(-17px);
  opacity: 1;
}

.lunar__detail__back:visited {
  color: #ffffff;
}

.lunar__form {
  max-width: 350px;
  color: #ffffff;
}

.lunar__moon-card__owner {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.lunar__moon-card__owner.unowned img {
  margin-right: 15px;
}

.lunar__moon-card .identicon {
  border-radius: 15px;
  margin-right: 10px;
}

.lunar__moon-card__address {
  overflow: hidden;
  font-weight: 200;
  font-size: 11.5px;
  display: block;
}

.lunar__moon-list-container {
  margin-top: -38px;
  display: flex;
  flex-direction: column;
}

.lunar__moon-list {
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  margin-bottom: 25px;
  transition: all .5s ease-in-out;
  overflow-y: scroll;
}

.lunar__moon-list::-webkit-scrollbar {
  display: none;
}

.lunar__moon-list a {
  text-decoration: none;
}

.lunar__moon-list__button {
  display: flex;
  height: 32px;
  justify-content: flex-end;
  color: white;
  padding-bottom: 7px;
  appearance: none;
  background: transparent;
  border: 0;
  text-align: right;
  cursor: pointer;
}

.lunar__moon-list__button svg {
  height: 16px;
}

.lunar__moon-list__button--reverse svg {
  transform: rotate(180deg);
}

.lunar__moon-list__title {
  font-size: 16px;
  color: #ffffff;
}

.lunar__moon-list-item {
  padding: 16px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #555;
}

.lunar__moon-list__price {
  display: flex;
  align-items: center;
}

.lunar__moon-list .identicon {
  border-radius: 15px;
  margin-right: 10px;
}

.lunar__input {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  color: white;
  margin-top: 20px;
  position: relative;
}

.lunar__input label {
  font-size: 14px;
  margin-bottom: 5px;
}

.lunar__input input {
  background: transparent;
  border: 1px solid white;
  padding: 10px;
  width: 100%;
  color: white;
  margin-bottom: 7px;
  font-size: 14px;
  border-radius: 4px;
  transition: all .2s ease;
}

.lunar__input input:disabled {
  opacity: .7;
}

.lunar__input__suffix {
  position: absolute;
  bottom: 17px;
  right: 10px;
  font-size: 13px;
  color: #cccccc;
}

.lunar__icon-info {
  background: url(".//images/icon_info.svg") no-repeat;
  background-size: 15px 15px;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
}

/* This should hide the default increment/decrement buttons */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 0;
  width: 0;
}

.lunar__form__price {

}

.lunar__form__prices {
  display: flex;
  flex-direction: row;
}

.lunar__form__prices .lunar__input:first-child {
  margin-right: 15px;
}

.lunar__footer {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 30px;
  max-width: 1200px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(0deg, black 50%, transparent);
  z-index: 10;
  pointer-events: none;
}

.lunar__footer a,
.lunar__footer button {
  pointer-events: auto;
}

@media (max-height: 700px) {
  .lunar__footer {
    position: relative;
  }
}

.lunar__footer img {
  width: 150px;
}

.lunar__info {
  background: url(".//images/info.svg") no-repeat;
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  border: 0;
  transition: all .2s ease;
}

.lunar__info:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.lunar__info:after {
  content: "FAQ";
  color: #ffffff;
  font-size: 14px;
  font-family: Open Sans, Arial, sans-serif;
  width: 100px;
  display: inline-block;
  transform: translateX(0) scale(0.9);
  opacity: 0;
  transition: all .2s ease;
}

.lunar__info:hover:after {
  transform: translateX(5px) scale(1);
  opacity: 1;
}

.lunar__info:active, .lunar__info:focus {
  outline: 0;
}

.lunar__moon-card {
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  margin-bottom: 25px;
  max-height: 300px;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

.lunar__moon-card--loading {
  max-height: 120px;
  transition-delay: .1s;
}

.lunar__moon-card__top {
  padding: 12px 15px;
  border-bottom: 1px solid #888;
  font-size: 16px;
  color: #ffffff;
}

.lunar__moon-card__body {
  padding: 15px;
  font-size: 13px;
  color: #ffffff;
  opacity: 0;
  animation: fadeIn .2s forwards;
}

.lunar__moon-card__price {
  display: block;
  margin: 0 0 15px;
  font-size: 15px;
}

.lunar__detail .lunar__cta {
  margin-top: 0;
}

.lunar__detail .lunar__form .lunar__cta {
  margin-top: 10px;
  margin-bottom: 10px;
}

.lunar__profile a {
  color: white;
}

.lunar__profile h3 {
  font-weight: 200;
}

.lunar__profile__placeholder {
  padding: 10px 20px;
  opacity: .7;
  border: 1px solid #ffffff;
  border-radius: 4px;
  margin: 0 0 25px;
}

.lunar__missing-web3-logo {
  width: 50px;
  display: block;
  margin: 20px auto;
}

.lunar__profile__link {
  text-decoration: none;
}

@keyframes scaleRight {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  100% {
    transform-origin: left;
    transform: scaleX(1);
  }
}

/* Loader */

.lunar__loader {
  position: relative;
  height: 30px;
  width: 30px;
  margin: 25px auto;
  opacity: 0;
  animation: fadeIn .2s forwards;
}

.lunar__loader:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid rgba(255,255,255,.4);
  border-top-color: rgba(255,255,255,.8);
  animation: spinner .5s linear infinite;
}

.lunar__cta .lunar__loader {
  display: inline;
  height: 20px;
  width: 20px;
  margin: 0 25px;
}

.lunar__cta .lunar__loader:before {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}

.lunar__modal {
  padding:30px;
  position: relative;
}

.lunar__faq {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  font-family: Open Sans;
}

.lunar__faq__close {
  position: absolute;
  top: 0px;
  right: 20px;
  width: 20px;
  height: 20px;
  background: url("./images/close.svg") no-repeat;
  background-size: 20px 20px;
  border: none;
  cursor: pointer;
}

.lunar__faq__question {
  border-bottom: 1px solid #999999;
  padding: 30px 0;
}

.lunar__faq__question h3 {
  font-weight: 100;
  color: #cccccc;
}

.lunar__faq__question a {
  color: white;
  text-decoration: underline;
}

.lunar__faq__question a:visited {
  color: white;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

@keyframes fadeIn {
  to {opacity: 1;}
}

